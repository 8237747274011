<template>
  <div class="text-center" v-if=!enable>
    <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
</template>
<script type="text/javascript">

  export default {
    props: {
      enable: Boolean
    }
};
</script>

<style type="text/css">
  .v-progress-circular__overlay{
    color: #F68D3A !important;
  }
  .v-progress-circular{
    margin-top:20px;
  }
</style>